import { useEffect } from "react";
import { useContext } from "react";
import { Web3Client } from "./clients/Web3Client";
import InfoDisplay from "./components/InfoDisplay";
import MintContext from "./store/mint-context";
import MintDisplay from "./components/MintDisplay";

import "./styles/App.css";

const TWITTER_HANDLE = "_buildspace";
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;

const App = () => {
  const mintContext = useContext(MintContext);
  const web3Client = new Web3Client();

  // TODO: see if we can have these be 1 call
  async function totalSupplyHander() {
    let supply = await web3Client.getTotalSupply();
    mintContext.setTotalSupply(supply.toNumber());
  }

  async function currentMintCountHandler() {
    let currentMintCount = await web3Client.getCurrentMintCount();
    mintContext.setCurrentMintCount(currentMintCount.toNumber());
  }

  async function mintPriceHandler() {
    let price = await web3Client.getMintPrice();
    mintContext.setMintPrice(price);
  }

  async function isSaleActiveHandler() {
    const state = await web3Client.isSaleActive();
    mintContext.setIsSaleActive(state);
  }

  async function maxPublicMintHandler() {
    let maxMints = await web3Client.getMaxPublicMint();
    mintContext.setMaxPublicMint(maxMints.toNumber());
  }

  // 2 make sure chain is the expected chain

  //ethereum.on('chainChanged', (_chainId) => window.location.reload());

  useEffect(() => {
    console.log("in use effect");
    totalSupplyHander();
    currentMintCountHandler();
    mintPriceHandler();
    isSaleActiveHandler();
    maxPublicMintHandler();
  }, []);

  /*
   * Added a conditional render! We don't want to show Connect to Wallet if we're already conencted :).
   */
  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <p className="header gradient-text">My NFT Collection</p>

          <InfoDisplay />
          <MintDisplay />
        </div>
        <div className="footer-container">
          <a
            className="footer-text"
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
          >{`minting provided by the incubator (insert twitter link here)`}</a>
        </div>
      </div>
    </div>
  );
};

export default App;
