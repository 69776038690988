import { useRef, useContext, useState } from "react";
import { ethers } from "ethers";
import myEpicNft from "../utils/MyEpicNFT.json";
import MintContext from "../store/mint-context.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Mint() {
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const mintContext = useContext(MintContext);
  const [loading, setLoading] = useState(false);
  const amountToMintRef = useRef();

  const mint = async () => {
    const amountToMint = amountToMintRef.current.value;
    console.log("Amount to mint: ", amountToMint);
    try {
      const { ethereum } = window;

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        contractAddress,
        myEpicNft.abi,
        signer
      );

      console.log("Going to pop wallet now to pay gas...");
      const amount =
        ethers.utils.parseUnits(mintContext.mintPrice, "ether") * amountToMint;
      let nftTxn = await connectedContract.makeAnEpicNFT(amountToMint, {
        value: amount,
      });

      const response = await toast.promise(nftTxn.wait(), {
        pending: "Minting NFT",
        success: {
          render({ data }) {
            const etherScanurl = `https://rinkeby.etherscan.io/tx/${data.transactionHash}`;
            return (
              <p>
                Successfully Minted!!
                <br />
                <a
                  className="light-link"
                  href={etherScanurl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See the transaction here on etherscan
                </a>
              </p>
            );
          },
          icon: "🚀",
        },
        error: {
          render({ error }) {
            return `Error occured while minting ${error.message}`;
          },
        },
      });
    } catch (error) {
      if (!error.code === 4001) {
        toast.error(`An error occurred ${error.message}`, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }
  };

  const range = (start, end) =>
    Array.from({ length: end }, (v, k) => k + start);

  return (
    <div>
      <select
        ref={amountToMintRef}
        id="mintquantity"
        name="mintquantity"
        data-name="mintquantity"
        className="mint-input w-select"
      >
        {range(1, mintContext.maxPublicMint).map((n) => (
          <option key={n} value={n}>
            {n}
          </option>
        ))}
      </select>

      <ToastContainer
        theme="dark"
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
      />
      <button
        onClick={() => mint()}
        className="cta-button connect-wallet-button"
      >
        Mint NFT
      </button>
    </div>
  );
}

export default Mint;
