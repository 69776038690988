import { createContext, useState } from "react";

const MintContext = createContext({
  maxPublicMint: "-",
  totalSupply: "-",
  currentMintCount: "-",
  mintPrice: "-",
  isSaleActive: false,
  // note: these aren't needed but are here just to help with autocompletion
  setMaxPublicMint: (value) => {},
  setTotalSupply: (value) => {},
  setCurrentMintCount: (value) => {},
  setMintPrice: (value) => {},
  setIsSaleActive: (value) => {},
});

export function MintContextProvider(props) {
  const [maxPublicMint, setMaxPublicMint] = useState([]);
  const [totalSupply, setTotalSupply] = useState([]);
  const [currentMintCount, setCurrentMintCount] = useState([]);
  const [mintPrice, setMintPrice] = useState([]);
  const [isSaleActive, setIsSaleActive] = useState([]);

  function setMaxPublicMintHandler(newMaxPublicMint) {
    setMaxPublicMint(newMaxPublicMint);
  }

  function setTotalSupplyHandler(newTotalSupply) {
    setTotalSupply(newTotalSupply);
  }

  function setCurrentMintCountHandler(newCurrentMint) {
    setCurrentMintCount(newCurrentMint);
  }

  function setMintPriceHandler(newMintPrice) {
    setMintPrice(newMintPrice);
  }

  function setIsSaleActiveHandler(state) {
    setIsSaleActive(state);
  }

  const context = {
    maxPublicMint: maxPublicMint,
    totalSupply: totalSupply,
    currentMintCount: currentMintCount,
    mintPrice: mintPrice,
    isSaleActive: isSaleActive,
    setMaxPublicMint: setMaxPublicMintHandler,
    setTotalSupply: setTotalSupplyHandler,
    setCurrentMintCount: setCurrentMintCountHandler,
    setMintPrice: setMintPriceHandler,
    setIsSaleActive: setIsSaleActiveHandler,
  };

  return (
    <MintContext.Provider value={context}>
      {props.children}
    </MintContext.Provider>
  );
}

export default MintContext;
