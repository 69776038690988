import { ethers } from "ethers";
// TODO: it would be cool if we could load this from an external source

import myEpicNft from "../utils/MyEpicNFT.json";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

export class Web3Client {
  getReadyOnlyProvider() {
    // TODO: this needs to be config driven
    // return new ethers.providers.JsonRpcProvider();
    return new ethers.providers.Web3Provider(window.ethereum);
  }

  getReadOnlyContract() {
    const provider = this.getReadyOnlyProvider();

    return new ethers.Contract(contractAddress, myEpicNft.abi, provider);
  }

  async getMaxPublicMint() {
    const contract = this.getReadOnlyContract();
    console.log("Getting maxPublicMint");
    let supply = contract.MAX_PUBLIC_MINT();
    return supply;
  }

  async getTotalSupply() {
    const contract = this.getReadOnlyContract();

    console.log("Getting total supply");
    let supply = contract.MAX_SUPPLY();
    return supply;
  }

  async getCurrentMintCount() {
    const contract = this.getReadOnlyContract();

    console.log("Getting mint count");
    let amount = contract.getAmountMinted();
    return amount;
  }

  async isSaleActive() {
    const contract = this.getReadOnlyContract();

    console.log("Getting sale state");
    let amount = contract.saleIsActive();
    return amount;
  }

  async getMintPrice() {
    const contract = this.getReadOnlyContract();

    console.log("Getting mint price");
    let formattedAmount = await contract
      .PRICE_PER_TOKEN()
      .then((value) => ethers.utils.formatEther(value));
    return formattedAmount;
  }
}
