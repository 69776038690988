import { useContext, useState, useEffect } from "react";
import MintContext from "../store/mint-context";
import Mint from "../components/Mint";
import InstallMetamask from "./InstallMetamask";
import MetaMaskOnboarding from "@metamask/onboarding";

function MintDisplay() {
  const mintContext = useContext(MintContext);
  const [chainId, setChainId] = useState(0);
  const [currentAccount, setCurrentAccount] = useState("");
  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  };

  const connectWallet = async () => {
    console.log("in connectWallet");
    try {
      const { ethereum } = window;
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkIfWalletIsConnected();
    getChainId();
  }, []);

  if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
    return <InstallMetamask />;
  }

  window.ethereum.on("chainChanged", handleChainChanged);
  function handleChainChanged(_chainId) {
    window.location.reload();
  }
  function toDecimal(hexString) {
    return parseInt(hexString, 16);
  }

  async function getChainId() {
    let hexChainId = await window.ethereum.request({ method: "eth_chainId" });
    let chainId = toDecimal(hexChainId);
    setChainId(chainId);
    console.log(
      `Chain id ${typeof chainId} valid id ${typeof parseInt(
        process.env.REACT_APP_EXPECTED_CHAIN_ID
      )}`
    );

    console.log(
      `Chain id ${chainId} valid id ${process.env.REACT_APP_EXPECTED_CHAIN_ID}`
    );
    return chainId;
  }

  if (chainId !== parseInt(process.env.REACT_APP_EXPECTED_CHAIN_ID)) {
    return (
      <div className="info-text">
        Your wallet is currently connected to a different network. Please change
        it to the {process.env.REACT_APP_EXPECTED_CHAIN_NAME} to continue
      </div>
    );
  }

  if (!mintContext.isSaleActive) {
    return (
      <div className="info-text">
        Mint is not active. Please come back later
      </div>
    );
  }

  if (mintContext.totalSupply === mintContext.currentMintCount) {
    return <div className="info-text">SOLD OUT!</div>;
  }

  if (currentAccount === "") {
    return (
      <button
        onClick={connectWallet}
        className="cta-button connect-wallet-button"
      >
        Connect to Wallet
      </button>
    );
  } else {
    return <Mint />;
  }
}

export default MintDisplay;
