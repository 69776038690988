import { useContext } from 'react';
import MintContext from "../store/mint-context.js";

function InfoDisplay() {
  const mintContext = useContext(MintContext);

  return (
    <h5 className="info-text">
      {mintContext.mintPrice} ETH each, {mintContext.currentMintCount} / {mintContext.totalSupply} minted
    </h5>
  );
}

export default InfoDisplay;
