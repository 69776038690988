import MetaMaskOnboarding from "@metamask/onboarding";

function InstallMetamask() {
  const onClick = () => {
    new MetaMaskOnboarding().startOnboarding();
  };
  return (
    <button onClick={onClick} className="cta-button connect-wallet-button">
      Install MetaMask
    </button>
  );
}

export default InstallMetamask;
